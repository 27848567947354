import React, {  useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ isOpen, setIsOpen }) => {
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.split("/")[1] == "PrimaryClient") {
      localStorage.setItem('tab', "primary")
    } else if (location.pathname.split("/")[1] == "UserList") {
      localStorage.setItem('tab', "users")
    } else if ((location.pathname.split("/")[1] !== "userInfo") && (location.pathname.split("/")[1] !== "PrimaryClient") && (location.pathname.split("/")[1] !== "UserList")) {

      localStorage.removeItem('tab');
    }
    if (location.pathname.split("/")[1] == "LoadManagement") {
      // setOpenLoad(true)
    } else {
      // setOpenLoad(false)

    }
  }, [location.pathname])


  return (
    <>

      <div className="sidebar-overlay">
        <section className="sidebar">
          <div className="logo">
            <img src={require("../assets/images/logo-ai.svg").default} alt="" />
          </div>
          <div className="">
            <Link to="/Home" className={(location.pathname == "/Home") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Dashboard
            </Link>
            {/* <Link to="/call-list" className={`sidebar_option ${location.pathname.includes('call-list') ? "active" : ""}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
              </svg>
              Call List
            </Link>
            
            <Link to="/availibility" className={`sidebar_option ${location.pathname.includes('availibility') ? "active" : ""}`}>
              <img
                src={require("../assets/images/avail.svg").default}
                alt=""
              />
              Availability  Schedule
            </Link>

            <Link to="/appointments" className={`sidebar_option ${location.pathname.includes('appointments') ? "active" : ""}`}>
              <img
                src={require("../assets/images/primary-client.svg").default}
                alt=""
              />
              Appointments
            </Link> */}
            <Link to="/user-list" className={`sidebar_option ${location.pathname.includes('user-list') ? "active" : ""}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
              </svg>
              Company Management
            </Link>
            
          </div>

        </section>
      </div>
    </>
  );
};
export default Sidebar;
