import React, { useState, useEffect } from "react";
import {  Col, Container, Row } from "react-bootstrap";
import { InlineLoader } from "../../components/loader";
import {  Link } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
import 'react-toastify/dist/ReactToastify.css';
import { aiService } from "../../service/api.service";

export const Home = () => {
  const [loader, setLoader] = useState(false);
  const [dashbordData, setDashbordData] = useState([]);
  useEffect(() => {
    // dashboardList()
  }, [])

  async function dashboardList() {
    setLoader(true)
    try {
      const response = await aiService.dashboard()
      if (response?.status == 200) {
        setDashbordData(response?.data)
        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)
        
        swal({icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
       
        swal({icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }


  return (
    <>
     <div className="dashboard-main-area">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <div className="call-list-head">
              <h2 className="align-self-center mb-2">Dashboard <img src={require("../../assets/images/dashboard-icon.gif")} /></h2>
            </div>
            </div>
            <div className="dashboard-area">
              <Row className="justify-content-center">
                <Col md={12}>
                  <Row>
                    {loader == true ? <ThreeDotSpinner /> : ""}
                
                    <Col lg={6} xl={6} xxl={3} className="mb-3">
                      <div className="dashboard-cards appointments">
                        <div className="card-img-view">
                          <img
                            src={require("../../assets/images/schedule-appoitment.png")}
                          />
                        </div>
                        <Link to="#" className="dashboardlink" >
                          <div className="card-content">
                            <h3>Total Schedule Appointment</h3>
                            <h2>
                              {" "}
                              {loader ?
                                <InlineLoader />
                                :
                                <>
                                  {dashbordData ? <>{dashbordData?.Appointments_count}</> : <>-</>}
                                </>
                              }
                            </h2>

                          </div>
                        </Link>
                      </div>
                    </Col>
                 
                    <Col lg={6} xl={6} xxl={3} className="mb-3">
                      <div className="dashboard-cards total-leads">
                        <div className="card-img-view">
                          <img
                            src={require("../../assets/images/total-leads.png")}
                          />
                        </div>
                        <Link to="#" className="dashboardlink">
                          <div className="card-content">
                            <h3>Total Leads</h3>
                            <h2>
                              {" "}
                              {loader ?
                                <InlineLoader />
                                :
                                <>
                                  {dashbordData ? <>{dashbordData?.contactus_count}</> : <></>}
                                </>
                              }
                            </h2>

                          </div>
                        </Link>
                      </div>
                    </Col>
             
                  </Row>

                </Col>
              </Row>
            </div>
        </Container>
      </div>
    </>
  );
};
