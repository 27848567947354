import { useEffect, useState } from "react"
import { ThreeDotSpinner } from "../loader"
import { Button, Col, Container, Form, Modal, Row, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import swal from "sweetalert";
import { aiService } from "../../service/api.service";
import Pagination from "react-js-pagination";

function UserList() {
  const [loader, setLoader] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(1)
  const [page, setPage] = useState(1)
  const [debouncedQuery, setDebouncedQuery] = useState(search);
  const page_size = 10

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim()
      .email('Invalid email format')
      .required('Email is required'),
    name: Yup.string().trim()
      .required('Name is required'),
    password: Yup.string().trim()
      .min(6, 'Password must be at least 6 characters long')
      .required('Password is required'),
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(search);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    getUserList()
  }, [page])

  useEffect(() => {
    const getData = setTimeout(() => {
      getUserList()
    }, 300);
    return () => clearTimeout(getData)
  }, [debouncedQuery])

  function handlePageChange(pageNumber) {
    setPage(pageNumber);
  }

  async function getUserList() {
    if (!search) {
      setLoader(true)
    }
    try {
      const users = await aiService.userList({ search, limit: page_size, page })
      setData(users.data.data)
      setTotalItems(users.data.total_count)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      }
    }
  }

  async function changeStatus(id, is_active) {
    swal({
      title: "Confirmation",
      text: `Are you sure you want to ${is_active ? "deactivate" : "activate"} the User?`,
      icon: "warning",
      buttons: ["NO", "YES"],
    }).then(async (val) => {
      if (val) {
        setLoader(true)
        try {
          await aiService.ChangeStatus(id, { is_active: is_active ? false : true })
          swal({ icon: "success", text: `User ${is_active ? "deactivated" : "activated"} successfully.` })
          await getUserList()
        } catch (error) {
          setLoader(false)
          if (error?.response?.status == 401) {
            swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
              localStorage.clear()
              window.location.href = "/";
            });
          }
          return false;
        }
      }
    })
  }

  async function deleteUser(id) {
    swal({
      title: "Confirmation",
      text: `Are you sure you want to delete the User?`,
      icon: "warning",
      buttons: ["NO", "YES"],
    }).then(async (val) => {
      if (val) {
        setLoader(true)
        try {
          await aiService.deleteUser(id)
          swal({ icon: "success", text: "User deleted successfully." })
          await getUserList()
        } catch (error) {
          setLoader(false)
          if (error?.response?.status == 401) {
            swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
              localStorage.clear()
              window.location.href = "/";
            });
          }
        }
      }
    })
  }

  const handleModalClose = () => {
    setAddModal(false)
  }

  return (
    <div className="dashboard-main-area company-list-area">
      {loader ? <ThreeDotSpinner /> : ""}
      <Container fluid>
        <div className="dashboard-area-heading management-heading">
          <div className="under-content-section">
            <div>
              <h2 className="">
                Company List
            
              </h2>
            </div>
            <div className="company-search-right">
            <Button variant="success" className="ms-3" onClick={() => setAddModal(true)}>
                  Add Company
                </Button>
                <div className="search-top-right">
                <Form.Control placeholder="Search by email, name..." value={search} onChange={(e) => {
                if (e.target.value.trim() != '') {
                  setSearch(e.target.value)
                } else {
                  setSearch("")
                }
              }} />
              <i class="fa fa-search" aria-hidden="true"></i>
                </div>
   
            </div>
          </div>
          <Row className="pb-4 justify-content-center">
            <Col md={12}>
              <div className="audit-request-box">
                <Table responsive="sm" className="UserListTable">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>Active</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length && data.map(u => (
                        <tr>
                          <td>
                            <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Click to {u.is_active ? "deactivate": "activate"} user</Tooltip>}
                            >
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={u.is_active}
                                onChange={() => changeStatus(u._id, u.is_active)}
                              />
                            </OverlayTrigger>
                          </td>
                          <td>
                            {u.name}
                          </td>
                          <td>{u.email}</td>
                          <td>
                            <Button size="sm" variant="danger" onClick={() => deleteUser(u._id)}>
                              <svg className="text-danger" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                              </svg>
                            </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={page_size}
                  totalItemsCount={totalItems}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                  prevPageText={"Prev"}
                  nextPageText={"Next"}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container >

      <Modal onHide={() => handleModalClose()} show={addModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ email: '', name: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setLoader(true)
              setSubmitting(false)
              try {
                await aiService.signUpUser(values)
                resetForm()
                setLoader(false)
                handleModalClose()
                swal({ icon: "success", text: 'Company created successfully.' })
                await getUserList()
              } catch (error) {
                setLoader(false)
                if (error?.response?.status == 401) {
                  swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear()
                    window.location.href = "/";
                  });
                } else {
                  swal({ icon: "error", text: error.response.data.message })
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter company email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formName" className="mt-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter company name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formPassword" className="mt-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-4"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default UserList